<template>
  <div
    class="container-space-form"
    v-if="typeContainer === 'space'"
    ref="COMPONENT"
    :style="{ ...stylesTypes[typeStyle].container, ...style }"
  >
    <!-- slider -->
    <div class="container-space-slider" v-if="isSlider">
      <div
        v-for="(part, index) in objectFormParts"
        :key="index"
        :class="
          `${classSlider} renderizaslide ` +
          `${slideShowingNumber === index ? '' : ' slide-hidden'}`
        "
      >
        <div
          :class="`container-form ${
            inputs.widthComplete === true && 'width-complete'
          }`"
          v-for="(inputs, indexInput) in part"
          :key="indexInput"
          :style="{ ...stylesTypes[typeStyle].inputs, ...styleFormContainer }"
        >
          <div
            :style="inputs.width !== undefined ? `width:${inputs.width}` : ``"
          >
            <SelectsCustom
              v-if="inputs.type === 'select'"
              :arraySelects="inputs.array"
              :label="inputs.label"
              :vModel="(response) => vModel(response, inputs)"
              :required="inputs.required"
              :vModelFather="ObjectResponse[inputs.name]"
            />
            <InputManagementData
              v-if="inputs.type === 'managementData'"
              :arrayManagementData="inputs.array"
              :label="inputs.label"
              :subData1="inputs.subData1"
              :subData2="inputs.subData2"
              :subData3="inputs.subData3"
              :functionEdit="(response) => inputs.functionEdit(response)"
              :vModel="(response) => vModel(response, inputs)"
              :functionAdd="(response) => inputs.functionAdd(response)"
              :functionDelete="(response) => inputs.functionDelete(response)"
              :required="inputs.required"
              :vModelFather="ObjectResponse[inputs.name]"
            />
            <CheckboxGroup
              v-if="inputs.type === 'checkbox'"
              :arrayCheckbox="inputs.array"
              :type="inputs.style"
              :vModel="(response) => vModel(response, inputs)"
              :required="inputs.required"
              :vModelFather="ObjectResponse[inputs.name]"
            />
            <RadiosGroup
              v-if="inputs.type === 'radio'"
              :arrayRadio="inputs.array"
              :type="inputs.style"
              :firstSelect="inputs.firstSelect"
              :vModel="(response) => vModel(response, inputs)"
              :required="inputs.required"
              :vModelFather="ObjectResponse[inputs.name]"
            />
            <InputsCustom
              v-if="
                inputs.type === 'text' ||
                inputs.type === 'date' ||
                inputs.type === 'number' ||
                inputs.type === 'password'
              "
              :type="inputs.type"
              :label="inputs.label"
              :vModel="(response) => {vModel(response, inputs)}"
              :vModelFather="ObjectResponse[inputs.name]"
              :style="inputs.style"
              :required="inputs.required"
              :error="primeraVezForm ? {is: false, text: ''} : inputsWithState[inputs.name].error"
              :limitNumbers="inputs.limitNumbers"
              :description="inputs.description"
              :valueDefault="inputs.valueDefault ? inputs.valueDefault : ''"          
            />
            <InputCodigoGroup
              v-if="inputs.type === 'codigo'"
              :arrayData="inputs.arrayInputs"
              :vModel="
                (response, inputByGroup) => vModel(response, inputByGroup)
              "
              :vModelFather="ObjectResponse[inputs.name]"
              :actionFather="this.clousureActionSecButton"
              :limitNumbers="inputs.limitNumbers"
            />
            <slot 
              v-if="inputs.type === 'custom'" 
              :name="inputs.name"
            >
            </slot>
          </div>
        </div>
      </div>
    </div>
    <!-- no slider -->
    <div
      v-else
      :class="`container-form ${
        inputs.widthComplete === true && 'width-complete'
      }`"
      v-for="(inputs, index) in objectForm"
      :key="index"
      :style="{ ...stylesTypes[typeStyle].inputs, ...styleFormContainer }"
    >
      <div :style="inputs.width !== undefined ? `width:${inputs.width}` : ``">
        <SelectsCustom
          v-if="inputs.type === 'select'"
          :arraySelects="inputs.array"
          :label="inputs.label"
          :vModel="(response) => vModel(response, inputs)"
          :vModelFather="ObjectResponse[inputs.name]"
          :required="inputs.required"
        />
        <InputManagementData
          v-if="inputs.type === 'managementData'"
          :arrayManagementData="inputs.array"
          :label="inputs.label"
          :subData1="inputs.subData1"
          :subData2="inputs.subData2"
          :subData3="inputs.subData3"
          :functionEdit="(response) => inputs.functionEdit(response)"
          :vModel="(response) => vModel(response, inputs)"
          :vModelFather="ObjectResponse[inputs.name]"
          :functionAdd="(response) => inputs.functionAdd(response)"
          :functionDelete="(response) => inputs.functionDelete(response)"
          :required="inputs.required"
        />
        <CheckboxGroup
          v-if="inputs.type === 'checkbox'"
          :arrayCheckbox="inputs.array"
          :type="inputs.style"
          :vModel="(response) => vModel(response, inputs)"
          :vModelFather="ObjectResponse[inputs.name]"
          :required="inputs.required"
        />
        <RadiosGroup
          v-if="inputs.type === 'radio'"
          :arrayRadio="inputs.array"
          :type="inputs.style"
          :firstSelect="inputs.firstSelect"
          :vModel="(response) => vModel(response, inputs)"
          :vModelFather="ObjectResponse[inputs.name]"
          :required="inputs.required"
        />
        <InputsCustom
          v-if="
            inputs.type === 'text' ||
            inputs.type === 'date' ||
            inputs.type === 'number' ||
            inputs.type === 'password'
          "
          :type="inputs.type"
          :label="inputs.label"
          :vModel="(response) => vModel(response, inputs)"
          :vModelFather="ObjectResponse[inputs.name]"
          :style="inputs.style"
          :required="inputs.required"
          :error="primeraVezForm ? {is: false, text: ''} : inputsWithState[inputs.name].error"          
          :limitNumbers="inputs.limitNumbers"
          :description="inputs.description"
          :valueDefault="inputs.valueDefault ? inputs.valueDefault : ''"
        />
        <InputCodigoGroup
          v-if="inputs.type === 'codigo'"
          :arrayData="inputs.arrayInputs"
          :vModel="(response, inputByGroup) => vModel(response, inputByGroup)"
          :vModelFather="ObjectResponse[inputs.name]"
          :actionFather="this.clousureActionSecButton"
          :limitNumbers="inputs.limitNumbers"
        />
        <slot 
          v-if="inputs.type === 'custom'" 
          :name="inputs.name"
        >
        </slot>
      </div>
    </div>
    <div class="container-p-before-buttons">
      <slot name="contentBeforeButton"></slot>
    </div>
    <!-- buttons -->
    <div class="width-complete" style="margin-top: 40px; margin-left: 0px">
      <button
        v-if="isSlider && slideShowingNumber === 0"
        class="button"
        @click="clousureActionFirstButton"
      >
        {{ "Borrar" }}
      </button>
      <button
        v-else-if="
          isSlider &&
          slideShowingNumber > 0 &&
          slideShowingNumber <= partsNumber
        "
        class="button"
        @click="changeSlideSpace('back')"
      >
        {{ "Anterior" }}
      </button>

      <button
        v-if="isSlider && slideShowingNumber < partsNumber - 1"
        :class="`button`"
        :style="'margin-left:100px;'"
        @click="changeSlideSpace('next')"
      >
        {{ "Siguiente" }}
      </button>
      <div
        :class="`${!isOneButton && !isSlider ? 'width-complete' : ''}`"
        v-else
      >
        <button class="button" v-if="!isOneButton && !isSlider">Borrar</button>
        <button
          :class="`button ${isOneButton && 'button-large'}`"
          :style="{
            marginLeft: `${!isOneButton && '100px'}`,
            ...buttons[1].style
          }"
          @click="clousureActionSecButton"
        >
          {{ textSecButton }}
        </button>
      </div>
    </div>
  </div>

  <!-- window-shadow -->
  <teleport to="body">
    <div
      :class="bulWindow ? `shadow-window` : `shadow-window-hidden`"
      v-if="typeContainer === 'window'"
      :style="bulWindow ? `width:${widthWindowForm};` : ``"
    ></div>
  </teleport>

  <!-- form-window -->
  <teleport to="body"> 
    <div
      class="container-window-right-form"
      :style="bulWindow ? `width:${widthWindowForm}; right:-0px;` : `width:${widthWindowForm}; right:-${widthWindowForm};`"
      v-if="typeContainer === 'window'"
    >
      <div class="x-window" @click="exitForm()"></div>
      <div class="title-form-window">{{ title }}</div>
      <div class="container-window-slider">
        <!-- window no slider -->
        <div
          class="container-window-slide"
          style="
            height: 100%;
            padding-bottom: 50px;
            overflow: hidden scroll;
            margin-top: 0px;
          "
          v-if="!isSlider"
        >
          <div
            class="container-window-inputs"
            v-for="(inputs, index) in objectForm"
            :key="index"
          >
            <SelectsCustom
              v-if="inputs.type === 'select'"
              :arraySelects="inputs.array"
              :label="inputs.label"
              :vModel="(response) => vModel(response, inputs)"
              :vModelFather="ObjectResponse[inputs.name]"
              :required="inputs.required"
            />
            <InputManagementData
              v-if="inputs.type === 'managementData'"
              :arrayManagementData="inputs.array"
              :label="inputs.label"
              :subData1="inputs.subData1"
              :subData2="inputs.subData2"
              :subData3="inputs.subData3"
              :functionEdit="(response) => inputs.functionEdit(response)"
              :vModel="(response) => vModel(response, inputs)"
              :vModelFather="ObjectResponse[inputs.name]"
              :functionAdd="(response) => inputs.functionAdd(response)"
              :functionDelete="(response) => inputs.functionDelete(response)"
              :required="inputs.required"
            />
            <CheckboxGroup
              v-if="inputs.type === 'checkbox'"
              :arrayCheckbox="inputs.array"
              :type="inputs.style"
              :vModel="(response) => vModel(response, inputs)"
              :vModelFather="ObjectResponse[inputs.name]"
              :required="inputs.required"
            />
            <RadiosGroup
              v-if="inputs.type === 'radio'"
              :arrayRadio="inputs.array"
              :type="inputs.style"
              :firstSelect="inputs.firstSelect"
              :vModel="(response) => vModel(response, inputs)"
              :vModelFather="ObjectResponse[inputs.name]"
              :required="inputs.required"
            />
            <InputsCustom
              v-if="
                inputs.type === 'text' ||
                inputs.type === 'date' ||
                inputs.type === 'number' ||
                inputs.type === 'email'
              "
              :type="inputs.type"
              :label="inputs.label"
              :vModel="(response) => vModel(response, inputs)"
              :vModelFather="ObjectResponse[inputs.name]"
              :required="inputs.required"
              :description="inputs.description"    
              :valueDefault="inputs.valueDefault ? inputs.valueDefault : ''"
              :error="primeraVezForm ? {is: false, text: ''} : inputsWithState[inputs.name].error"
            />
            <slot 
              v-if="inputs.type === 'custom'" 
              :name="inputs.name"
            >
            </slot>
          </div>
        </div>

        <!-- window slider -->
        <div
          class="container-window-slide"
          :style="{ marginTop: `${slideShowingNumber * -80}vh` }"
          v-if="isSlider"
        >
          <div
            class="container-window-inputs"
            style="height: 80vh; overflow: hidden scroll; padding-bottom: 75px"
            v-for="(part, indexPart) in objectFormParts"
            :key="indexPart"
          >
            <div
              class="container-window-inputs"
              v-for="(inputs, index) in part"
              :key="index"
            >
              <div>
                <SelectsCustom
                  v-if="inputs.type === 'select'"
                  :arraySelects="inputs.array"
                  :label="inputs.label"
                  :vModel="(response) => vModel(response, inputs)"
                  :vModelFather="ObjectResponse[inputs.name]"
                  :required="inputs.required"
                />
                <InputManagementData
                  v-if="inputs.type === 'managementData'"
                  :arrayManagementData="inputs.array"
                  :label="inputs.label"
                  :subData1="inputs.subData1"
                  :subData2="inputs.subData2"
                  :subData3="inputs.subData3"
                  :functionEdit="(response) => inputs.functionEdit(response)"
                  :vModel="(response) => vModel(response, inputs)"
                  :vModelFather="ObjectResponse[inputs.name]"
                  :functionAdd="(response) => inputs.functionAdd(response)"
                  :functionDelete="(response) => inputs.functionDelete(response)"
                  :required="inputs.required"
                />
                <CheckboxGroup
                  v-if="inputs.type === 'checkbox'"
                  :arrayCheckbox="inputs.array"
                  :type="inputs.style"
                  :vModel="(response) => vModel(response, inputs)"
                  :vModelFather="ObjectResponse[inputs.name]"
                  :required="inputs.required"
                />
                <RadiosGroup
                  v-if="inputs.type === 'radio'"
                  :arrayRadio="inputs.array"
                  :type="inputs.style"
                  :firstSelect="inputs.firstSelect"
                  :vModel="(response) => vModel(response, inputs)"
                  :vModelFather="ObjectResponse[inputs.name]"
                  :required="inputs.required"
                />
                <InputsCustom
                  v-if="
                    inputs.type === 'text' ||
                    inputs.type === 'date' ||
                    inputs.type === 'number'
                  "
                  :type="inputs.type"
                  :label="inputs.label"
                  :vModel="(response) => vModel(response, inputs)"
                  :vModelFather="ObjectResponse[inputs.name]"
                  :required="inputs.required"
                  :error="primeraVezForm ? {is: false, text: ''} : inputsWithState[inputs.name].error"
                  :limitNumbers="inputs.limitNumbers"
                  :description="inputs.description"
                  :valueDefault="inputs.valueDefault ? inputs.valueDefault : ''"
                />
                <InputCodigoGroup
                  v-if="inputs.type === 'codigo'"
                  :arrayData="inputs.arrayInputs"
                  :vModel="
                    (response, inputByGroup) => vModel(response, inputByGroup)
                  "
                  :vModelFather="ObjectResponse[inputs.name]"
                  :actionFather="this.clousureActionSecButton"
                  :limitNumbers="inputs.limitNumbers"
                />
                <slot 
                  v-if="inputs.type === 'custom'" 
                  :name="inputs.name"
                >
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-window-inputs flex-button-window">
        <button v-if="isSlider && slideShowingNumber === 0" class="button">
          Borrar
        </button>
        <button
          v-else-if="
            isSlider &&
            slideShowingNumber > 0 &&
            slideShowingNumber <= partsNumber
          "
          class="button"
          @click="changeSlideSpace('back')"
        >
          {{ "Anterior" }}
        </button>
        <button
          v-if="isSlider && slideShowingNumber < partsNumber - 1"
          class="button"
          style="margin-left: 40px"
          @click="changeSlideSpace('next')"
        >
          Siguiente
        </button>
        <div
          :class="`${!isOneButton && !isSlider ? 'width-complete' : ''}`"
          v-else
        >
          <button class="button" v-if="!isOneButton && !isSlider">Borrar</button>
          <button
            class="button"
            :style="{
              marginLeft: '40px',
              ...buttons[1].style
            }"
            @click="clousureActionSecButton"
          >
            {{ textSecButton }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import SelectsCustom from "./SelectsCustom.vue";
import InputManagementData from "./InputManagementData.vue";
import CheckboxGroup from "./CheckboxGroup.vue";
import RadiosGroup from "./RadiosGroup.vue";
import InputsCustom from "./InputsCustom.vue";
import InputCodigo from "./InputCodigo.vue";
import InputCodigoGroup from "./InputCodigoGroup.vue";
import { formCustomRow, formCustomColumn } from "../styles/formCustomStyles";
import { mapActions, mapState } from 'vuex';

export default {
  name: "FormCustom",
  components: {
    SelectsCustom,
    InputManagementData,
    CheckboxGroup,
    RadiosGroup,
    InputsCustom,
    InputCodigo,
    InputCodigoGroup,
  },
  props: {
    typeContainer: {
      type: String,
      default: "space",
    },
    widthWindowForm: {
      type: String,
      default: "400px"
    },
    title: {
      type: String,
      default: "",
    },
    objectForm: {
      type: Array,
      default: [
        {
          type: "text",
          label: "ejemplo",
          vmodel: () => {},
        },
      ],
    },
    bulWindow: {
      type: Boolean,
      default: false,
    },
    exitForm: {
      type: Function,
      default: () => {},
    },
    limitInputsInSection: {
      type: Number,
      default: 100,
    },
    actionFirstButton: {
      type: Function,
      default: () => {},
    },
    actionSecButton: {
      type: Function,
      default: () => {},
    },
    style: {
      type: Object,
      default: {},
    },
    styleFormContainer: {
      type: Object,
      default: {},
    },
    textSecButton: {
      type: String,
      default: "Siguiente",
    },
    typeStyle: {
      type: String,
      default: "row",
    },
    isOneButton: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: [{
        text: "",
        action: () => {},
        style: {},
      },
      {
        text: "",
        action: () => {},
        style: () => {}
      }
      ]
    },
    reiniciarDataForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let objectFormParts = [];
    let partsLimit = 0;

    // si la longitud es mayor al limite lo dividimos en partes
    if (this.objectForm.length > this.limitInputsInSection) {
      // sacamos la cantidad de partes que se pueden sacar
      partsLimit = Math.ceil(
        this.objectForm.length / this.limitInputsInSection
      );

      //dividimos el objectForm en partes(arrays) en un nuevo array
      //de esta manera reducimos el costo del loop a la mitad
      for (let part = 1; part <= partsLimit; part++) {
        objectFormParts.push(
          this.objectForm.slice(
            this.limitInputsInSection * (part - 1),
            this.limitInputsInSection * part
          )
        );
      }
    }
    return {
      //estados forms
      primeraVezForm: true,
      formReiniciandose: false,

      //Specials ref
      classSlider: 'container-space-slide-ready',
      clock: 0,

      //Slider
      showFirstSlide: true,
      isSlider: this.objectForm.length > this.limitInputsInSection,
      objectFormParts: objectFormParts,
      partsNumber: partsLimit,
      slideShowingNumber: 0,

      //Estilos que complementan con la prop typeStyle
      stylesTypes: {
        column: formCustomColumn,
        row: formCustomRow,
      },

      //Objeto de los distintos inputs y su estado
      inputsWithState: Object.fromEntries(
        new Map([
          ...this.objectForm.map((input) => {
            if (input.type === "radio" || input.type === "checkbox") {
              let arrayCheckboxMemory = new Object();

              input.array.forEach((data) => {
                arrayCheckboxMemory[data] = false;
              });

              return [input.name, {
                ...input,
                error: {
                  is: false,
                  text: ""
                },
                value: arrayCheckboxMemory
              }];
            } else {
              return [input.name, {
                ...input,
                error: {
                  is: false,
                  text: ""
                },
                value: input.valueDefault ? input.valueDefault : ""
              }];
            }            
          })
        ])
      ),

      //Estado padre
      ObjectResponse: Object.fromEntries(
        //Objeto a partir de key y values de un map
        new Map([
          ...this.objectForm.map((input) => {
            if (input.type === "radio" || input.type === "checkbox") {
              let arrayCheckboxMemory = new Object();

              input.array.forEach((data) => {
                arrayCheckboxMemory[data] = false;
              });

              // creamos los entries
              //[key, value]
              return [input.name, arrayCheckboxMemory];
            } else {
              return [input.name, input.valueDefault ? input.valueDefault : ""];
            }
          }), 
        ])
      ),
    };
  },
  methods: {
    ...mapActions(["setEventSubmit"]),
    //Cambiamos el margin del primer slide para que suba o baje y actualizamos para que tome el nuevo valor
    setMarginTopSlide(value) {
      this.marginTopSlide = value;
      this.$forceUpdate();
    },
    //Cambia si se muestra el primer slide o no, en caso de que sea no significa que se muestra el segundo.
    changeSlideSpace(action) {
      this.classSlider = "container-space-slide"
      clearTimeout(this.clock)
      this.clock = setTimeout(() => {
        this.classSlider = "container-space-slide-ready"
      }, 600);

      const actions = {
        back: -1,
        next: 1,
      };
      this.slideShowingNumber = this.slideShowingNumber + actions[action];
    },
    //manejadores del evento click
    async clousureActionFirstButton($event) {
      this.actionFirstButton($event);
    },
    async clousureActionSecButton() {
      let inputsRequiredsSinData = false
      let terminoRevisionRequeridos = false

      this.objectForm.forEach((input, index)=> {
        // si es el ultimo termina la revision entonces cambiamos el valor de finalRevisionsRequireds
        if(index === this.objectForm.length - 1) terminoRevisionRequeridos = true

        // si el input es requerido y en el objeto de response esta sin data hay que avisar y cambiar el estado en "inputsWithState"
        // tambien cambiar la variable local "inputsRequiredsSinData" asi el metodo "mandarData" no manda nada
        if(input.required && this.ObjectResponse[input.name] === ""){
          inputsRequiredsSinData = true
          this.inputsWithState[input.name].error = { is: true, text: `¡${input.label} es requerido!`}
        }
      })
      this.primeraVezForm = false

      //esperamos a que se revisen todos los inputs requireds
      const esperarRevisionDeRequeridos = async() => {
        // si termina la revision de los inputs requireds se manda la data, sino se espera unos 100ms mas
        if(terminoRevisionRequeridos){
          //si hay inputs required sin data termina la funcion, si hay data llama a la funcion mandarData
          if(inputsRequiredsSinData) return
          else {
            return this.mandarData()
          }
        }else{
          return setTimeout(esperarRevisionDeRequeridos, 100)
        }
      }
      return esperarRevisionDeRequeridos()
    },
    async mandarData(){
      //creamos un nuevo objeto data para no referenciar al estado ObjectResponse y que cambie mientras se este usando afuera de formCustom
      const data = JSON.parse(JSON.stringify(this.ObjectResponse))
      await this.actionSecButton(data)
      return
    },
    reiniciarData(){
      //reinicio de form y su data
      this.formReiniciandose = true
      let terminoReinicioDataInputs = false

      this.objectForm.forEach((input, index) => {
        if (input.type === "radio" || input.type === "checkbox") {
          let arrayCheckboxMemory = new Object();

          input.array.forEach((key) => {
            arrayCheckboxMemory[key] = false;
          });
          this.ObjectResponse[input.name] = arrayCheckboxMemory;          
        } else {
          this.ObjectResponse[input.name] = input.valueDefault ? input.valueDefault : "";
        }
          if(index === this.objectForm.length - 1){
            terminoReinicioDataInputs = true
          }
      })

      //esperamos que se reinicie la data de los inputs
      const esperarReinicioDeDataForm = () => {
        // cuando se termina el reinicio de la data se pasa a reiniciar los datos restantes de 
        if(terminoReinicioDataInputs){
          this.primeraVezForm = true
          return setTimeout(this.reiniciarDatosRestantesInputs, 40)
        }else{
          return setTimeout(esperarReinicioDeDataForm, 100)
        }
      } 
      return esperarReinicioDeDataForm()
    },
    reiniciarDatosRestantesInputs(){
      this.objectForm.forEach((input, index) => {

        if (input.type === "radio" || input.type === "checkbox") {
          let arrayCheckboxMemory = new Object();

          input.array.forEach((data) => {
            arrayCheckboxMemory[data] = false;
          });

          this.inputsWithState[input.name].value = arrayCheckboxMemory;
          this.inputsWithState[input.name].error = {is: false, text: ""};
          
        } else {
          this.inputsWithState[input.name].value = "";
          this.inputsWithState[input.name].error = {is: false, text: ""};
        }

        if(index === this.objectForm.length - 1){
          this.formReiniciandose = false
        }
      })

    },
    vModel(response, input) {
      // el form se esta reiniciando no queremos sobrescribir la data desde los inputs
      if(this.formReiniciandose === true) return
      // dejo esto para los que todavia no tengan implementado la supresion de estado
      if (typeof input.vmodel === "function") {
        input.vmodel(response);
      }

      if(this.primeraVezForm){
        this.primeraVezForm = false
      }

      let value = response;

      this.ObjectResponse[input.name] = value;
      this.inputsWithState[input.name].value = value;
      this.inputsWithState[input.name].error = {
        is: this.inputsWithState[input.name].required && value === "",
        text: this.inputsWithState[input.name].required && value === "" ? `¡${input.label} es requerido!` : ""
      }
    },
  },
  created() {
    //Preparamos datos para el form window
    this.marginTopSlide = 0;
    // En el caso de que el ObjectForm sea mayor al limite de inputs mostrados por pantalla se separa para un futuro slider
    if (this.objectForm.length > this.limitInputsInSection) {
      this.objectForm1 = this.objectForm.slice(0, this.limitInputsInSection);
      this.objectForm2 = this.objectForm.slice(this.limitInputsInSection);
    }
    const elementosFormWindow = `
        .butonTabs, 
        .container-window-slide, 
        .container-window-inputs, 
        .container-window-right-form, 
        .flex-button-window,
        .container-modal,
        .letras-selection-input-custom,
        .box-mensaje-true,
        .container-hover,
        .space,
        .shadow-window,
        .card,
        .caja,
        .caja-reduce,
        .container-window-slide *, 
        .container-window-inputs *, 
        .container-window-right-form *, 
        .flex-button-window *, 
        .container-modal *,
        .card *,
        .letras-selection-input-custom *,
        .shadow-window *,
        .box-mensaje-true *,
        button,
        label,
        .container-hover *,
        .container-input-search-filter,
        .container-input-search-filter *,
        .space *
    `
    // si se hace click y no matchea con algun elemento de form-window se activa la funcion que cierra el form
    document.addEventListener("mousedown", (e)=>{
      if(!e.target.matches(elementosFormWindow)){ 
        this.exitForm()
      }
    })
  
  },
  computed:{
    ...mapState(["errorAnInputRequired", "eventSubmit"])
  },
  watch: {
    objectForm(newObjectForm) {
      let objectFormParts = [];
      let partsLimit = 0;
      // si la longitud es mayor al limite lo dividimos en partes
      if (newObjectForm.length > this.limitInputsInSection) {
        // sacamos la cantidad de partes que se pueden sacar
        partsLimit = Math.ceil(
          newObjectForm.length / this.limitInputsInSection
        );

        //dividimos el objectForm en partes(arrays) en un nuevo array
        //de esta manera reducimos el costo del loop a la mitad
        for (let part = 1; part <= partsLimit; part++) {
          objectFormParts.push(
            newObjectForm.slice(
              this.limitInputsInSection * (part - 1),
              this.limitInputsInSection * part
            )
          );
        }
        this.objectFormParts = objectFormParts;
      }
    },
    reiniciarDataForm(newValue){
      if(newValue === true){
        this.reiniciarData()
      }
    }
  },
};
</script>

<style scoped>
.container-space-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.container-form {
  width: 40%;
  height: auto;
  margin-left: 5%;
  margin-right: 5%;
}
.width-complete {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  height: 35px;
  border: none;
  border-radius: 10px;
  background: #297f87;
  color: white;
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  z-index: 10;
  width: 150px;
  margin-left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-window-right-form {
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 40;
  background: linear-gradient(
    169.26deg,
    rgba(255, 255, 255, 0.84) 100%,
    rgba(255, 255, 255, 0.55) 100%
  );
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 60px 00px;
  padding-top: 120px;
  transition: all 0.4s ease-in-out;
  backdrop-filter: blur(4px);
  overflow: hidden scroll;
}
.container-window-inputs {
  width: calc(100% + 2.5px);
  padding: 0px 15px;
  height: auto;
}
.flex-button-window {
  margin-top: 20px;
  display: flex;
  width: calc(100% + 2.5px);
  padding: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
  height: auto;
  overflow: hidden;
  justify-content: center;
}
.x-window {
  position: absolute;
  top: 32.5px;
  width: 15.5px;
  height: 15.5px;
  background-repeat: no-repeat;
  background-image: url(../assets/pictures/x-negro.svg);
  transition: all 0.5s;
  right: 15px;
  cursor: pointer;
}
.shadow-window-hidden,
.shadow-window {
  position: fixed;
  -webkit-box-shadow: inset -12px 0 8px -8px rgb(0 0 0 / 24%);
  box-shadow: inset -12px 0 8px -8px rgb(0 0 0 / 24%);
  top: 0px;
  width: 35px;
  height: 100vh;
  right: -20px;
  content: open-quote;
  transition: all 0.4s ease-in-out;
}
.shadow-window {
  transition: all 0.4s ease-in-out;
}
.title-form-window {
  position: absolute;
  top: 0px;
  left: 30px;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container-p-before-buttons {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  z-index: 10;
}
.p-before-buttons {
  width: 100%;
  color: black;
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  margin: 0px;
}
.container-window-slider {
  overflow: hidden;
  height: 100%;
}
.container-window-slide {
  transition: margin-top 0.8s ease;
  height: 100%;
}
.container-space-slide {
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  transition: margin-top 0.8s ease;
  animation: showSlideSpace 0.5s linear forwards;
}

.container-space-slide-ready {
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  transition: margin-top 0.8s ease;
  
}
.slide-hidden {
  position: absolute;
  opacity: 0;
  animation: none;
  transform: translateX(-100%);
}
.button-large {
  width: 600.22px;
  padding: 25px;
}
@keyframes showSlideSpace {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 800px) {
  .container-form {
    width: 90%;
  }
}
</style>

<template>
    <div :class="`radio-group-container-component ${(arrayRadio.length > 1) ? 'two' : 'one'}`">
        <div 
            class="containerRadio"
            v-for="(session,index) in arrayRadioFunction(arrayRadio)"
            :key="index"
        >
            <div 
                :class="objectStyles[0][type]" 
                @click="selectRadioFunction(session)"
            >
                    <div v-if="arrayRadioData[session] === true" :class="objectStyles[1][type]"></div>
                    <div v-if="arrayRadioData[session] === false" :class="objectStyles[2][type]"></div>
            </div>
            <p class="texto-radio">{{session}}</p>
        </div>
    </div>
</template>

<script>
import { watch } from '@vue/runtime-core'
    export default {
        name: "RadiosGroup",
        props: {
            arrayRadio: {
                type: Array,
                default: [],
            },
            type: {
                type: Array,
                default: 'checkbox'
            },
            firstSelect: {
                type: Boolean,
                default: false
            },
            vModel: {
                type: Function,
                default: () => {}
            },
            vModelFather: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return{
                numberBefore: 0,
                arrayRadioData: {},
                selectRadio: '',
                bulIteraded: false,
                objectStyles: [
                    {radio: 'radio-circular', checkbox: 'radio'},
                    {radio: 'caja-circular', checkbox: 'caja'},
                    {radio: 'caja-reduce-circular', checkbox: 'caja-reduce'}
                ]
            }
        },
        methods:{
            //funcion creadora de array para contorlar los radio
            arrayRadioFunction(array){
                if(!this.bulIteraded){
                    let arrayRadioMemory = new Object()
                    this.bulIteraded = true

                    array.forEach((data,index) => {
                        if(index === 0 && this.firstSelect === true){
                            arrayRadioMemory[data] = true
                            this.selectRadio = data
                            this.vModel(data)
                        }else{
                            arrayRadioMemory[data] = false
                        }
                    })

                    this.arrayRadioData = arrayRadioMemory
                }  

                return array
            },
            //funcion para seleccionar o deseleccionar los checkbox
            selectRadioFunction(session){
                if(this.selectRadio === '' && this.firstSelect === false){
                   this.arrayRadioData[session] = true
                   this.selectRadio = session
                }else{
                    this.arrayRadioData[this.selectRadio] = false
                    this.arrayRadioData[session] = true
                    this.selectRadio = session
                }
                this.vModel(this.selectRadio)
            }
        },
        watch:{
            data(newValue){
                //selectRadioFunction se encarga de setear la data de radiosGroup
                this.selectRadioFunction(newValue)
            },            
            vModelFather(newValue){
                if(newValue !== this.data){
                    this.selectRadioFunction(newValue)
                }
            }
        }
    }
</script>

<style scoped>
    .radio-group-container-component{
        display: flex;
        flex-direction: row;
    }
    .one{
        justify-content: center;
    }
    .two{
        justify-content: space-between;
    }
    .containerRadio{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .radio{
        width: 25px;
        height: 25px;
        border-radius:4px;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .radio-circular{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 10px;
        margin-top:-8px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .caja,.caja-reduce{
        width: 17px;
        height: 17px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        transform: scale(1);
        animation: animateCaja 0.4s ease-in-out;
    }
    .caja-reduce{
        opacity: 0;
        transform: scale(0.2);
        animation: animateCajaReduce 0.4s ease-in-out;
    }
    .caja-circular,.caja-reduce-circular{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
        animation: animateCaja 0.4s ease-in-out;
    }
    .caja-reduce-circular{
        opacity: 0;
        transform: scale(0.2);
        animation: animateCajaReduce 0.4s ease-in-out;
    }
    @keyframes animateCaja{
        0%{
            opacity: 0;
            transform: scale(0.2);
        }
        100%{
            opacity: 1;
            transform: scale(1);
        }
    }
    @keyframes animateCajaReduce{
        0%{
            opacity: 1;
            transform: scale(1);
        }
        100%{
            opacity: 0;
            transform: scale(0.2);
        }
    }
    .texto-radio{
        margin-top: 6px;
        white-space: nowrap;
    }
</style>
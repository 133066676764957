<template>
     <div 
        class="containerInput"
    >
        <label :for="label" class="label">{{label}}</label>
        <select :id="label" class="input" v-model="data">
            <option 
                class="option" 
                v-for="(value,index) in arraySelects" 
                :key="index" 
                v-bind:value="value"
            >
                {{value}}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "SelectsCustom",
        props: {
            arraySelects: {
                type: Array,
                default: []
            },
            label: {
                type: String,
                default: ''
            },
            vModel:{
                type: Function,
                default: () => {}
            },
            vModelFather:{
                type: Function,
                default: () => {}
            }
        },
        data() {
            return{
                data: ''
            }
        },
        watch: {
            data(newValue){
                this.vModel(newValue)
            },
            vModelFather(newValue){
                if(newValue !== this.data){
                    this.data = newValue
                }
            }
        }
    }
</script>

<style scoped>
    .containerInput{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 3px;
        height: 35px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
        margin-top:0px ;
    }
    .label{
        font-family: Rubik;
        margin-bottom: 2px;
        font-weight:bold ;
    }
    .option{
        border: #6464646b 2px solid;
    }
</style>
<template>
    <div :class="`checkbox-group-container-component ${(arrayCheckbox.length > 1) ? 'two' : 'one'}`">
        <div 
            class="containerCheckbox"
            v-for="(session,index) in arrayCheckboxFunction(arrayCheckbox)"
            :key="index"
        >
            <div 
                :class="objectStyles[0][type]" 
                @click="selectCheckboxFunction(session)"
            >
                    <div v-if="arrayCheckboxData[session] === true" :class="objectStyles[1][type]"></div>
                    <div v-if="arrayCheckboxData[session] === false" :class="objectStyles[2][type]"></div>
            </div>
            <p class="texto-check">{{session}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CheckboxGroup",
        props: {
            arrayCheckbox: {
                type: Array,
                default: [],
            },
            type: {
                type: String,
                default: 'checkbox'
            },
            vModel: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return{
                numberBefore: 0,
                arrayCheckboxData: {},
                bulIteraded: false,
                objectStyles: [
                    {radio: 'checkbox-circular', checkbox: 'checkbox'},
                    {radio: 'caja-circular', checkbox: 'caja'},
                    {radio: 'caja-reduce-circular', checkbox: 'caja-reduce'}
                ]
            }
        },
        methods:{
            //funcion creadora de array para contorlar los checkbox
            arrayCheckboxFunction(array){
                if(!this.bulIteraded){
                    let arrayCheckboxMemory = new Object()
                    this.bulIteraded = true

                    array.forEach((data) => {
                        arrayCheckboxMemory[data] = false
                    })

                    this.arrayCheckboxData = arrayCheckboxMemory
                }  

                return array
            },
            //funcion para seleccionar o deseleccionar los checkbox
            selectCheckboxFunction(session){
                if(this.arrayCheckboxData[session] === true){
                    this.arrayCheckboxData[session] = false
                }else{
                    this.arrayCheckboxData[session] = true
                }
                this.vModel(this.arrayCheckboxData)
            }
        },
    }
</script>

<style scoped>
    .checkbox-group-container-component{
        display: flex;
        flex-direction: row;
    }
    .one{
        justify-content: center;
    }
    .two{
        justify-content: space-between;
    }
    .containerCheckbox{
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .checkbox{
        width: 25px;
        height: 25px;
        border-radius:4px;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .checkbox-circular{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 10px;
        margin-top:-8px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .caja,.caja-reduce{
        width: 17px;
        height: 17px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        transform: scale(1);
        animation: animateCaja 0.4s ease-in-out;
    }
    .caja-reduce{
        opacity: 0;
        transform: scale(0.2);
        animation: animateCajaReduce 0.4s ease-in-out;
    }
    .caja-circular,.caja-reduce-circular{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
        animation: animateCaja 0.4s ease-in-out;
    }
    .caja-reduce-circular{
        opacity: 0;
        transform: scale(0.2);
        animation: animateCajaReduce 0.4s ease-in-out;
    }
    @keyframes animateCaja{
        0%{
            opacity: 0;
            transform: scale(0.2);
        }
        100%{
            opacity: 1;
            transform: scale(1);
        }
    }
    @keyframes animateCajaReduce{
        0%{
            opacity: 1;
            transform: scale(1);
        }
        100%{
            opacity: 0;
            transform: scale(0.2);
        }
    }
    .texto-check{
        margin-top: 6px;
    }
</style>
<template>
    <input 
    type="text" 
    :id="name" 
    :class="className" 
    v-model="data"
    @input="($event)=>jumpRight($event)"
    :style="{height: height}"
    />
</template>

<script>
    export default {
        name: "InputCodigo",
        props: {
            className: {
                type: String,
                default: 'input'
            },
            vModel:{
                type: Function,
                default: () => {}
            },
            style: {
                type: Object,
                default: {}
            },
            required: {
                type: Boolean,
                default: false
            },
            changePosition:{
                type: Function,
                default: () => {}
            },
            positionInput: {
                type: Number,
                default: 0
            },
            positionGlobal: {
                type: Number,
                default: 0
            },
            clear: {
                type: Boolean,
                default: false
            },
            limitNumbers: {
                type: Number,
                default: 1,
            },
            height: {
                type: String,
                default: ""
            }
        },
        data() {
            return{
                data: '',
                errorRequired: false,
                focus: this.positionInput === this.positionGlobal,
                min: 10 ** (this.limitNumbers - 1) - 1,
                max: 10 ** this.limitNumbers - 1,
            }
        },
        methods: {
            jumpRight($event){
                if(this.data.length >= this.limitNumbers){
                    // Si no es un numero valido o tiene espacios se resetea data y el input
                    if(isNaN(this.data) || / +/g.test(this.data)){ 
                        $event.target.value = ""
                        this.data = ""
                    }else {
                        this.changePosition() 
                        // si todo va bien cambia de posicion, aunque si es el ultimo input hace el submit(o actionFather que se le pase).
                    }
                }
            },
            setErrorRequired() {
                this.errorRequired = this.data === ""
            },
        },
        mounted(){
            // Nos fijamos si en si prop focus es true y enfocamos el elemento en caso asi sea
            if(this.focus){
                this.$el.focus()
            }
        },
        watch: {
            // si desde el padre se manda que hay que borrar inputs este cambia su data
            clear(newValue){
                if(newValue){
                    this.data = ""
                }
            },
            data(newData){
                this.vModel(newData)
            },
            // Posicion del input que le toca enfocarse
            positionGlobal(newPosition){
                this.focus = newPosition === this.positionInput
            },
            // Miramos que este enfocado o no
            focus(newValue){
                if(newValue){
                    this.$el.focus()
                }
            }
        }
    }
</script>

<style scoped>
    .input{
        background: white;
        border-radius:12.2px;
        border: #6464646b 2px solid;
        font-size: 16px ;
        height: 60px;
        width: 60px;
        padding-bottom: 3px;
        padding-top: 3px;
        text-align: center;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none; 
        margin: 0; 
    }

    input[type=number] {
        -moz-appearance:textfield; 
    }

</style>
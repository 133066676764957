export const formCustomRow = {
    container:{
        width: '100%',
        maxWidth: '1000.2px',
    },
}

export const formCustomColumn = {
    container:{
        width: '100%', 
        padding: '10px 3px',    
    },
    inputs: { 
        marginLeft: '0',  
        marginRight: '0', 
        width: '100%',
    }
}
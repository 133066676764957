<template>
    <p class="p-text-source" :style="`line-height: ${lineHeightProp}`">
        {{text}}
    </p>
    <div class="container-hover" >
    </div>
</template>

<script>

    export default {
        name: "TextComponent",
        props: {
            text: {
                type: String,
                default: ''
            },
            lineHeightProp: {
                type: String,
                default: 'auto'
            }
        },
    }
</script>

<style scoped>
    .p-text-source{
        width: auto;
        max-width: calc(100% + 0px);
        display: inline-block;
        height: 100%;
        margin: 0px;
        padding: 0px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis; 
        transition: all 0.75s ease-in-out;
    }
    .container-hover{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
<template>
    <div>
        <InputCodigo 
            v-for="(input, index) in arrayData" 
            :key="index" 
            :vModel="(response) => vModel(response, input)"
            :clear="clearAllInputs"
            :positionInput="index"
            :positionGlobal="focusPosition"
            :changePosition=" arrayData.length - 1 === index 
                ? submitCodigo
                : changePositionInput 
            "
            :height="input.height"
            :limitNumbers="limitNumbers"
        />
    </div>
</template>


<script>
import InputCodigo from './InputCodigo.vue'

export default {
    name: "InputCodigoGroup",
    components: {
        InputCodigo
    },
    props:{
        arrayData: {
            type: Array,
            default: []
        },
        focusPosition: {
            type: Number,
            default: 0,
        },
        vModel: {
            type: Function,
            default: () => {}
        },
        vModelFather:{
            type: Function,
            default: () => {}
        },
        actionFather: {
            type: Function,
            default: () => {}
        },
        limitNumbers: {
            type: Number,
            default: 1,
        }
    },
    data(){
        return {
            dataGroup: Object.fromEntries(
                new Map([
                    ...this.arrayData
                        .map(input => [
                            input.name, ""
                        ])
                ])
            ),
            focusPosition: 0,
            clearAllInputs: false            
        }
    },
    methods: {
        changePositionInput(){
            this.focusPosition = this.focusPosition + 1 // cambiamos el numero de foco
        },
        clearInputs(){
            this.clearAllInputs = !this.clearAllInputs
        },
        async submitCodigo (){
            await this.actionFather()
            this.clearInputs()
            this.focusPosition = 0
            setTimeout(()=>{
                this.clearInputs()
            }, 100)
        }        
    },
}
</script>


<style scoped>
    div{
        display: flex;
        gap: 20px;
        justify-content: center;
    }
</style>
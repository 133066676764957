<template>
    <div 
        class="container-input"
        @mouseover="bulCajaSobre = true" 
        @mouseleave="viewFeelOver" 
        @click="bulCajaSobre = true" 
        @blur="viewFeelOver"
    >
        <label :for="label" class="label">{{label}}</label>
        <div class="distribution-container" v-if="subData1 !== '' && subData2 === '' && subData3 === false">
            <input type="text" :id="label" class="input-alone" v-model="data1"/>
            <div class="sumar-otra-data" @click="()=>{
                functionAdd(response)
                vaciarInputs()
            }"></div>
        </div>
        <div class="distribution-container" v-if="subData1 !== '' && subData2 !== '' && subData3 === false">
            <input type="text" :id="label" class="input1" v-model="data1"/>
            <input type="text" class="input2" v-model="data2"/>
            <div class="sumar-otra-data" @click="()=> {
                functionAdd(response)
                vaciarInputs()
            }"></div>
        </div>
        <div class="distribution-container" v-if="subData1 !== '' && subData2 !== '' && subData3 !== false">
            <input type="text" :id="label" class="input1" v-model="data1"/>
            <input type="text" class="input2" v-model="data2"/>
            <div 
                class="cuadro-de-color" 
                :style="`${colorSeleccionadoCodigo(data3)}`" 
                @mouseover="relojColor('poner')" 
                @mouseleave="relojColor('quitar')" 
                @click="relojColor('poner')" 
                @blur="relojColor('quitar')"
            ></div>
            <div class="sumar-otra-data" @click="()=> {
                functionAdd(response)
                vaciarInputs()
            }"></div>
        </div>
        <div :class="'v-model-input-custom' + ` ${bulCajaSobre ? 'v-model-input-custom-visible' : 'v-model-input-custom-hidden'}`">
            <div 
                class="selection-input-custom" 
                v-for="(dataIteraded, index) in this.arrayManagementDataFiltered" 
                :key="index"
                >
                <!-- texto unico -->
                <div class="letras-selection-input-custom" style="width:100%;" v-if="subData1 !== '' && subData2 === '' && subData3 === false"
                    @click="
                        data1 = dataIteraded[`${subData1}`]; 
                        data2 = dataIteraded[`${subData2}`]; 
                        data3 = dataIteraded.Tipo_codigo; 
                    "
                >
                    <TextComponent :text="dataIteraded[`${subData1}`]"/>
                </div>
                <!-- texto doble -->
                <!-- texto izquierda -->
                <div class="letras-selection-input-custom" :style="`width:20%; ${colorSeleccionadoCodigo(dataIteraded.Tipo_codigo)}`" v-if="subData1 !== '' && subData2 !== '' && subData3 !== false"
                    @click="
                        data1 = dataIteraded[`${subData1}`]; 
                        data2 = dataIteraded[`${subData2}`]; 
                        data3 = dataIteraded.Tipo_codigo; 
                    "
                >
                    <TextComponent :text="dataIteraded[`${subData1}`]"/>
                </div>
                <!-- texto en medio -->
                <div class="letras-selection-input-custom" :style="`width:60%; ${colorSeleccionadoCodigo(dataIteraded.Tipo_codigo)}`" v-if="subData1 !== '' && subData2 !== '' && subData3 !== false"
                    @click="
                        data1 = dataIteraded[`${subData1}`]; 
                        data2 = dataIteraded[`${subData2}`]; 
                        data3 = dataIteraded.Tipo_codigo; 
                    "
                >
                    <TextComponent :text="dataIteraded[`${subData2}`]"/>
                </div>
                <div class="d-flex justify-content-center align-items-center" :style="`width:10%; ${colorSeleccionadoCodigo(dataIteraded.Tipo_codigo)}`">
                    <div 
                        class="editar-selection-input-custom" 
                        @click="
                            bulEditData = true;
                            dataSelect = {
                                ...dataIteraded,
                                data1: dataIteraded[`${subData1}`],
                                data2: dataIteraded[`${subData2}`],
                                data3: dataIteraded.Tipo_codigo,
                            }
                        "
                    ></div>
                </div>
                <div class="d-flex justify-content-center align-items-center" :style="`width:10%; ${colorSeleccionadoCodigo(dataIteraded.Tipo_codigo)}`">
                    <div 
                        class="borrar-selection-input-custom" 
                        @click="async () => {
                            await functionDelete(dataIteraded)
                            vaciarInputs()
                        }"
                    ></div>    
                </div>
            </div>
        </div>
        <div 
            class="caja-color-management-data" 
            v-if="bulCambioColor" 
            @mouseover="relojColor('poner');" 
            @mouseleave="relojColor('quitar')"
        >
            <div class="option-box-color" @click="data3 = 'encabezado'">
                <div class="box-green"></div>
                <div class="text-box">Encabezado</div>
            </div>
            <div class="option-box-color" @click="data3 = 'titulo'">
                <div class="box-gray"></div>
                <div class="text-box">Titulo</div>
            </div>
            <div class="option-box-color" @click="data3 = 'codigo'">
                <div class="box-white"></div>
                <div class="text-box">Codigo</div>
            </div>
        </div>
    </div>
    <div 
        class="container-modal" 
        ref="MODAL" 
        :style="(bulEditData) ? 'display:flex;' : 'display:none;' " 
        @click="functionOut($event)"
    >
        <div :class="(subData3 !== false) ? 'container-input-edit-cc' : 'container-input-edit'">
            <div class="x-new"></div>
            <div 
                class="container-input"
            >
                <label :for="label" class="label">{{label}}</label>
                <div class="distribution-container" v-if="subData1 !== '' && subData2 === '' && subData3 === false">
                    <input type="text" :id="label" class="input-alone" v-model="dataSelect[`data1`]"/>
                </div>
                <div class="distribution-container" v-if="subData1 !== '' && subData2 !== '' && subData3 === false">
                    <input type="text" :id="label" class="input1" v-model="dataSelect[`data1`]"/>
                    <input type="text" class="input2" v-model="dataSelect[`data2`]"/>
                </div>
                <!-- input subdata3 true -->
                <div class="distribution-container" v-if="subData1 !== '' && subData2 !== '' && subData3 !== false">
                    <input type="text" :id="label" class="input1" v-model="dataSelect[`data1`]"/>
                    <input type="text" class="input2" v-model="dataSelect[`data2`]"/>
                    <div 
                        class="cuadro-de-color" 
                        :style="`${colorSeleccionadoCodigoEdit(dataSelect[`data3`]) + ';top: 7px; right: 10px;' }`" 
                        @mouseover="relojColorEdit('poner')" 
                        @mouseleave="relojColorEdit('quitar')" 
                        @click="relojColorEdit('poner')" 
                        @blur="relojColorEdit('quitar')"
                    ></div>
                    <!-- simbolo agregar quitado -->
                </div>
                <div 
                    class="caja-color-management-data" 
                    v-if="bulCambioColorEdit" 
                    @mouseover="relojColorEdit('poner');" 
                    @mouseleave="relojColorEdit('quitar')"
                >
                    <div class="option-box-color" @click="dataSelect[`data3`] = 'encabezado'">
                        <div class="box-green"></div>
                        <div class="text-box">Encabezado</div>
                    </div>
                    <div class="option-box-color" @click="dataSelect[`data3`] = 'titulo'">
                        <div class="box-gray"></div>
                        <div class="text-box">Titulo</div>
                    </div>
                    <div class="option-box-color" @click="dataSelect[`data3`] = 'codigo'">
                        <div class="box-white"></div>
                        <div class="text-box">Codigo</div>
                    </div>
                </div>
            </div>
            <div class="container-button">
                <button 
                    class="button" 
                    @click="() => {
                        bulEditData = false
                        functionEdit(dataSelect)
                        vaciarInputs()
                    }"
                >Edit</button>
            </div>
        </div>
    </div>
</template>

<script>
    // @click="
    // this.$store.commit('SET_BUL_MODAL', true); 
    // bulEditaCaja = true; 
    // nombreMemoria = dataIteraded.Nombre_caja; 
    // cambioCaja1 = dataIteraded.Nombre_caja; 
    // idCaja = dataIteraded.Id_caja; 
    // codigoCaja = dataIteraded.Codigo_caja; 
    // ccCaja = dataIteraded.Cc_caja;"

    //eliminarCaja(cajaSeleccionados.Id_caja,cajaSeleccionados.Nombre_caja,cajaSeleccionados.Cc_caja)
    import {mapState} from "vuex"
    import TextComponent from "./TextComponent.vue"

    export default {
        name: "InputManagementData",
        components: {
            TextComponent
        },
        props: {
            arrayManagementData: {
                type: Array,
                default: []
            },
            label: {
                type: String,
                default: ''
            },
            subData1: {
                type: String,
                default: ''
            },
            subData2: {
                type: String,
                default: ''
            },
            subData3: {
                type: Boolean,
                default: false
            },
            vModel:{
                type: Function,
                default: () => {}
            },
            vModelFather: {
                type: Function,
                default: () => {}
            },
            functionEdit: {
                type: Function,
                default: () => {}
            },
            functionDelete: {
                type: Function,
                default: () => {}
            },
            functionAdd: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return{
                data1: '',
                data2: '',
                data3: 'codigo',
                dataSelect: {},
                relojCodigo: '',
                relojCodigoEdit: '',
                response: {},
                bulCajaSobre: false,
                bulCambioColor: false,
                bulCambioColorEdit: false,
                bulEditData: false,
                arrayManagementDataFiltered: this.arrayManagementData,
                isMouseOverInputManagment: false
            }
        },
        computed:{
            ...mapState([
                "bulModal",
                "isOpenManagmentData"
            ]),
        },
        mounted() {
            document.addEventListener('mouseover', this.handlerIsMouseOverInputManagment)
            window.document.children[0].children[1].appendChild(this.$refs.MODAL)
            if(this.subData1 !== ''){
                this.response[this.subData1] = ''
            }
            if(this.subData2 !== ''){
                this.response[this.subData2] = ''
            }
            if(this.subData3 !== false){
                this.response['color'] = 'codigo'
            }
        },
        methods: {
            handlerIsMouseOverInputManagment(e){
                const selectorsInputManagmentData = `
                    .container-hover,
                    .letras-selection-input-custom,
                    .box-mensaje-true,
                    .box-mensaje-false,
                    .selection-input-custom,
                    .arrow-box
                `
                if(!(e.target)) return

                const condition = (e.target.parentElement !== undefined)
                ? e.target.matches(selectorsInputManagmentData)
                : e.target.matches(selectorsInputManagmentData) || e.target.parentElement.className === ".letras-selection-input-custom"

                if(condition){
                    this.isMouseOverInputManagment = true
                }else{
                    this.isMouseOverInputManagment = false
                }
            },
            viewFeelOver(){
                if(this.isMouseOverInputManagment){
                    const esperar = setTimeout(()=>{
                        this.viewFeelOver()
                        clearTimeout(esperar)
                    }, 100)
                }
                else{
                    this.bulCajaSobre = false
                }
            },
            functionOut(e){
                if(e.target.className === "container-modal" || e.target.className === "x-new"){
                    this.bulEditData = false
                }
            },
            relojColor(tipo){
                if(tipo === 'quitar'){
                    this.relojCodigo = setTimeout(() => {
                        this.bulCambioColor = false
                    }, 500); 
                }
                if(tipo === 'poner'){
                    clearTimeout(this.relojCodigo)
                    this.bulCambioColor = true
                }
            },
            relojColorEdit(tipo){
                if(tipo === 'quitar'){
                    this.relojCodigoEdit = setTimeout(() => {
                        this.bulCambioColorEdit = false
                    }, 500); 
                }
                if(tipo === 'poner'){
                    clearTimeout(this.relojCodigoEdit)
                    this.bulCambioColorEdit = true
                }
            },
            colorSeleccionadoCodigo(value){
                if(value !== undefined){
                    if(value === 'encabezado'){
                        this.response['color'] = 'encabezado'
                        return 'background:#38c1ce;'
                    }
                    if(value === 'titulo'){
                        this.response['color'] = 'titulo'
                        return 'background:rgb(219, 219, 219);'
                    }
                    if(value === 'codigo'){
                        this.response['color'] = 'codigo'
                        return 'background:rgba(255, 255, 255, 1);'
                    }
                }else{
                    return ''
                }
            },
            colorSeleccionadoCodigoEdit(value){
                if(value === 'encabezado'){
                    this.dataSelect[`data3`] = 'encabezado'
                    return 'background:#38c1ce;'
                }
                if(value === 'titulo'){
                    this.dataSelect[`data3`] = 'titulo'
                    return 'background:rgb(219, 219, 219);'
                }
                if(value === 'codigo'){
                    this.dataSelect[`data3`] = 'codigo'
                    return 'background:rgba(255, 255, 255, 1);'
                }  
            },
            filteringByInput(inputText, subData){
                if(inputText !== undefined && inputText !== "" && subData) {
                    const searched = RegExp(`${inputText}`, "msi")
                    this.arrayManagementDataFiltered = this.arrayManagementData.filter(el => searched.test(el[`${subData}`]) )
                }else if (inputText === "" && subData){
                    this.arrayManagementDataFiltered = this.arrayManagementData
                }else if(inputText === undefined && subData){
                    this.arrayManagementDataFiltered = this.arrayManagementData                    
                }
            },
            vaciarInputs(){
                this.data1 = ''
                this.data2 = ''
                this.arrayManagementDataFiltered = this.arrayManagementData   
            }
        },
        watch: {
            data1(newValue){
                if(this.subData1 !== ''){
                    this.response[this.subData1] = newValue
                }
                if(this.subData2 !== ''){
                    this.response[this.subData2] = this.data2
                }
                this.filteringByInput(newValue, this.subData1)
                this.vModel(this.response)
            },
            data2(newValue){
                if(this.subData1 !== ''){ 
                    this.response[this.subData1] = this.data1
                }
                if(this.subData2 !== ''){
                    this.response[this.subData2] = newValue
                }
                this.filteringByInput(newValue, this.subData2)
                this.vModel(this.response)
            },
            arrayManagementData(newValue){
                this.arrayManagementDataFiltered = newValue
            },
            vModelFather(newValue){
                if(this.subData1 && !this.subData2){
                    if(this.data1 !== newValue[this.subData1]){
                        this.data1 = newValue[this.subData1]
                    }
                }
                else if(this.subData1 && this.subData2){
                    if(this.data1 !== newValue[this.subData1]){
                        this.data1 = newValue[this.subData1]
                    }
                    if(this.data2 !== newValue[this.subData2]){
                        this.data2 = newValue[this.subData2]
                    }
                }
            },
            $route() {
                window.document.children[0].children[1].removeChild(this.$refs.MODAL)
            }
        }
    }
</script>

<style scoped>
    .container-input{
        width: 100%;
        display: flex;
        flex-direction: column;
        position:relative; 
        height:60px; 
        margin-bottom:20px;
        padding-left:0.75rem ;
        padding-right:0.75rem ;
    }
    .distribution-container{
        display: flex;
        flex-direction: row;
        position:relative; 
        height:35px;
    }
    .option-box-color{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .option-box-color:hover{
        background: #29282c77;
    }
    .cuadro-de-color{
        width: 20px;
        height: 20px;
        border:#6464646b 2px solid;
        border-radius: 4px;
        position: absolute;
        top: 7px;
        right: 35px;
        z-index: 10;
    }
    .caja-color-management-data{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-content: space-around;
        position: absolute;
        width: 150px;
        right: 0px;
        height: 90px;
        background: white;
        border-radius: 10px;
        z-index: 20;
        top:-75px;
        padding: 10px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
    }
    .box-green, .box-gray, .box-white{
        background:#38c1ce ;
        height:20px;
        width:20px;
        border:1px solid rgb(71, 71, 71);
        cursor:pointer;
    }
    .box-gray{
        background:rgb(219, 219, 219);
    }
    .box-white{
        background:rgba(255, 255, 255, 1);
    }
    .text-box{
        font-weight:bold; 
        margin-left:15px; 
        cursor:pointer;
    }
    .input1, .input2{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        height: 35px;
        background: white;
        margin-left:0%;
        font-size:16px ;
        margin-top:0px ;
    }
    .input1{
        margin-bottom: 0px;
        width: 30%;
        border-radius:10px 0px 0px 10px;
    }
    .input2{
        margin-bottom: 0px;
        width: 70%;
        border-radius:0px 10px 10px 0px;
        border-left: #6464646b 0px solid;
        padding-right: 30px;
    }
    .input-alone{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        z-index: 20;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 35px;
        background: white;
        font-size:16px ;
        margin:0px ;
    }
    .label{
        font-family: Rubik;
        margin-bottom: 2px;
        font-weight:bold ;
    }
    .v-model-input-custom{
        display: flex;
        flex-direction: column;
        position: absolute;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: calc(100% - 1.5rem);
        height: 150px;
        top:56px;
        z-index: 50;
        border-radius:10px;
        background: rgb(253,253,254);
        overflow: hidden scroll;
        padding: 10px;
    }
    .selection-input-custom{
        cursor: pointer;
        width: 100%;
        height: 35px;
        border-bottom: #6464646b 2px solid;
        transition: all 0.4s;
        display: flex;
        flex-direction: row;
    }
    .letras-selection-input-custom{
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        position: relative;
        text-overflow: ellipsis;
        overflow:hidden;
        white-space:pre;
        padding: 0 2px;
    }
    .sumar-otra-data{
        position: absolute;
        top:1px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/agregar-input.svg);
        transition: all 0.5s;
        margin-top:6px;
        right: 7.5px;
        z-index: 20;
        cursor: pointer;
    }
    .editar-selection-input-custom{
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/lapiz.svg);
        transition: all 0.5s;
        z-index: 20;
        cursor: pointer;
    }
    .borrar-selection-input-custom{
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/papelera.svg);
        transition: all 0.5s;
        z-index: 20;
        cursor: pointer;
    }
    .container-modal{
        z-index: 100;
        top: 0px;
        left: 0px;
        position: fixed;
        width: 100%;
        height: auto;
        min-height: 100vh;
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .container-input-edit,.container-input-edit-cc{
        width: 300px;
        height: auto;
        position:relative;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 100%, rgba(255, 255, 255, 0.85) 100%);
        border-radius: 16px;
        padding: 40px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        backdrop-filter: blur(2px);
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
    }
    .container-input-edit-cc{
        width: 400px;
    }
    .container-button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
    .button{
        height: 30px;
        border:none;
        border-radius:5px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        width: 150px;
        margin-left: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .x-new{
        position: absolute;
        top:15px;
        right: 15px;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/x-negro.svg);
        transition: all 0.5s;
        cursor: pointer;
    }

    .v-model-input-custom-hidden{
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }

    .v-model-input-custom-visible{
        visibility: visible;
        opacity: 1;
    }
</style>
<template>
     <div 
        class="container"
        :style="style"
    >
        <label :for="label" :class="`label ${error.is ? 'label-error' : ''}`">
            <TextComponent :text="`${error.is ? error.text : label}`" />
        </label>
        <div class="container-input">
            <input 
            :type="type" 
            :id="label" 
            :class="`${className} ${description ? 'no-border-right' : ''}`" 
            v-model="data"
            />
            <div class="description" v-if="description">
                <i 
                    class="icon-description" 
                    ref="ICONINFO" 
                    @mouseover="bulInformation=true" 
                    @mouseleave="bulInformation=false"
                    
                    />
                <div class="touch-hidden" @mouseover="bulInformation=true" @mouseleave="bulInformation=false"/>
                    <div class="container-globo">
                <teleport to="body">
                        <div 
                            @mouseover="bulInformation=true"
                            @mouseleave="bulInformation=false"
                            :class="`globo-description ${bulInformation && sizeGloboOk ? 'globo-description-visible' : ''}`"
                            :style="{
                                left: `${Math.abs(positionButtonInformation.left - sizeGloboInfo.width + 34)}px`,
                                top: `${Math.abs(positionButtonInformation.top - sizeGloboInfo.height - 34 )}px`,
                                height: stylesGloboInfo.height + 'px',
                                maxHeight: sizeGloboInfo.maxHeight + 'px',
                            }"
                            ref="GLOBOINFO"
                        >
                            <span :class="`${bulInformation && sizeGloboOk ? 'span-visible' : ''}`">
                                {{description}}
                            </span>
                        </div>
                        <div 
                            :class="`polygon ${bulInformation && sizeGloboOk ? 'polygon-visible' : ''}`" 
                            :style="{
                                left: `${Math.abs(positionButtonInformation.left + 0)}px`,
                                top: `${Math.abs(positionButtonInformation.top - 14)}px`
                            }">

                        </div>
                </teleport>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TextComponent from "./TextComponent.vue"

    export default {
        name: "InputsCustom",
        components: {
            TextComponent,
        },
        props: {
            type: {
                type: String,
                default: 'text'
                //others
                // - date
                // - number
            },
            label: {
                type: String,
                default: ''
            },
            className: {
                type: String,
                default: 'input'
            },
            vModelFather: {
                type: String,
                default: ''
            },
            vModel:{
                type: Function,
                default: () => {}
            },
            style: {
                type: Object,
                default: {}
            },
            required: {
                type: Boolean,
                default: false
            },
            limitNumbers: {
                type: Number,
                default: 1000000000000000,
            },
            description: {
                type: String,
                default: "",
            },
            valueDefault: {
                type: String,
                default: "",
            },
            error: {
                type: Object,
                default: {
                    is: false,
                    text: "",
                },
            },
        },
        data() {
            return{
                data: this.valueDefault || "",
                bulInformation: false,
                positionButtonInformation: {
                    left: "0",
                    top: "0",
                    right: "0",
                    bottom: "0",
                },
                sizeGloboInfo: {
                    width: "0",
                    height: "0",
                    maxHeight: "400"
                },
                stylesGloboInfo: {
                    height: 'max-content',
                    width: 'max-content',
                    maxWidth: 600
                },
                sizeGloboOk: false,
            }
        },
        methods: {
            adjustSizeGlobo() {
                // Sacamos los datos necesarios
                const {left, top, right, bottom} = this.$refs.ICONINFO.getBoundingClientRect()
                let {width, height} = this.$refs.GLOBOINFO.getBoundingClientRect()

                // si el alto del globo es mayor al espacio en pantalla 
                if( height > top - window.pageYOffset){
                    window.scroll(0, window.pageYOffset - (top + height))
                    if(window.pageYOffset === 0){
                        this.$refs.GLOBOINFO.style.height = `${top - 24}px`
                        height = top - 24
                        this.stylesGloboInfo.height = height
                        this.sizeGloboInfo.maxHeight = top - 24
                        this.bulInformation = false
                    }
                }
                this.positionButtonInformation = { left, top, right, bottom } // posiciones para el globo
                this.sizeGloboInfo = {...this.sizeGloboInfo, width, height} //info de las dimensiones del globo

                //booleanos que permiten el renderizado al globo
                this.bulInformation = true
                this.sizeGloboOk = height < top - window.pageYOffset 

                //por un problema de renderizado le damos el ok para que se renderize despues de 800ms
                if(!this.sizeGloboOk){
                    setTimeout(()=>{
                        this.sizeGloboOk = true
                    }, 800)
                } 
            },
        },
        computed:{
        },
        watch: {
            valueDefault(newValue) {
                this.data = newValue
            },
            data(newValue){
                this.vModel(newValue)
            },
            'props.required'(newValue){
                this.required = newValue
            },
            vModelFather(newValue){
                if(newValue !== this.data){
                    this.data = newValue
                }
            },
            bulInformation(newValue){
                if(newValue === true){
                    this.adjustSizeGlobo()
                }
            },
        }
    }
</script>

<style scoped>
    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        height: 35px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
        margin-top:0px ;
    }
    .no-border-right{
        border-right: 2px transparent;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input2{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 35px;
        margin-right: 10px;
        margin-bottom:10px;
        font-size:16px ;
        background: transparent;
    }
    .label{
        font-family: Rubik;
        font-weight:bold ;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        position: relative;
    }
    .label-error{
        animation: errorChangeColor 0.1s ease forwards;
    }
    .container-input{
        position: relative;
        display: flex;
    }
    .description{
        align-items: center;
        background-color: #FFFFFF;
        border: #6464646b 2px solid;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-left: 2px transparent;
        border-top-left-radius: 0;
        display: flex;
        height: 35px;
        padding-left: 3px;
        width: 35px;
        z-index: 10;
    }
    .description .icon-description {
        background-image: url("../assets/pictures/icon-description.svg");
        width: 24px;
        height: 24px;
    }
    .globo-description{
        background-color: rgba(0, 0, 0, 0.85);
        border-radius: 11px;
        color: white;
        display: flex;
        height: max-content;
        justify-content: center;
        opacity: 0;
        padding: 0px;
        position: fixed;
        transition: opacity 1s ease-in;
        visibility: hidden;
        width: max-content;
        max-width: 300px;
        z-index: 100;
    }

    .icon-description .container-globo{
        opacity: 0;
        transition: opacity 2s ease;
        background-color: #C35555;
        visibility: hidden;
    }

    .icon-description:hover ~ .container-globo {
        visibility: visible;
        opacity: 1;
        transition: opacity .1s linear;
    }

    .globo-description-visible{
        visibility: visible;
        opacity: 1;
        overflow-y: auto;
        padding: 10px;
    }

    /* cada vez que se hace hover sobre estos elemento aparece el span */
    .span-visible{
        opacity: 1;
        visibility: visible;
    }
    span{
        visibility: hidden;
        opacity: 0;
        font-size: 12px;
        overflow-y: scroll;
        height: 100%;
        /* max-height: 52px; */
        transition: opacity .8s ease-in;
    }

    input:focus ~ .description {
        outline: 1px solid black;
    }

    .touch-hidden{
        width: 0px;
        height: 0px;
        position: absolute;
        top: -10px;
        right: 0px;
        transition: width .4s ease-out, height .4s ease-out;
    }

    .icon-description:hover ~ .touch-hidden, 
    .description:hover  .touch-hidden{
        width: 35px;
        height: 10px;        
    }

    .polygon{
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        transform: translateX(-3px) translateY(-0.5px);
        background-color: rgba(0, 0, 0, 0.85);
        position: fixed;
        height: 8px;
        width: 30px;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        transition: opacity 1s ease-in;
    }

    .polygon-visible {
        visibility: visible;
        opacity: 1;
    }
    @keyframes errorChangeColor {
        0%{
        }
        10%{
            color: #C35555;
        }
        100%{
            color: #C35555;
        }
    }
</style>